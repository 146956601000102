import React from 'react';
import { Button, Container, Form, FormGroup, Input, Label, Row, Col, Table } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import authUtils from "./authUtils";
import './AccountOverzicht.css';

export const AccountOverzicht = () => {
    const {
        user,
        rrnr,
        setRrnr,
        rrnrPlaceholder,
        isValidRrnr,
        events,
        donations,
        updateUserMetadata,
        getUserMetaData,
        calculateTotalDonations,
        fetchFiscaalAttest
    } = authUtils();

    const navigate = useNavigate()
    const handleRowClick = (eventId) => {
        // Navigate to another page when row is clicked
        navigate(`/detail/${eventId}`);
    };

    return (
        <Container className="general">
            <Row>
                <Row>
                    <h2>Mijn evenementen</h2>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Event Naam</th>
                                <th>Organisator</th>
                                <th>Datum</th>

                            </tr>
                        </thead>
                        <tbody>
                            {events.length === 0 ? (
                                <tr>
                                    <td colSpan="4">Geen evenementen gevonden</td>
                                </tr>
                            ) : (
                                events.map((event, index) => (
                                    <tr className="rowStyle" key={event.id || index} onClick={() => handleRowClick(event.id)}
                                    >
                                        <td>{event.eventNaam}</td>
                                        <td>{event.organisator}</td>
                                        {/* Assuming startDatum and eindDatum are strings that can be directly displayed */}
                                        <td>{`${event.startDatum} - ${event.eindDatum}`}</td>
                                    </tr>

                                ))
                            )}
                        </tbody>
                    </Table>
                </Row>
                <Row className="donations-table">
                    <h2>Mijn donaties</h2>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Gedoneerd op</th>
                                <th>Hoeveelheid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {donations.length === 0 ? (
                                <tr>
                                    <td colSpan="2">Geen donaties gevonden</td>
                                </tr>
                            ) : (
                                donations.map((donation, index) => (
                                    <tr key={donation.donationDate || index}>
                                        <td>{donation.donationDate}</td>
                                        <td>{donation.amount}</td>
                                    </tr>
                                ))
                            )}
                            <td className="text">Totaal
                                gedoneerd: {calculateTotalDonations(donations)} euro
                            </td>
                        </tbody>
                    </Table>
                </Row>
                <Row>
                        <h4 className="text">Een fiscaal attest is beschikbaar vanaf 40 euro.</h4>
                </Row>
            </Row>
        </Container>
    );
}
export default AccountOverzicht;