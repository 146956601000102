import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { AccountOverzicht } from './AccountOverzicht';
import authUtils from './authUtils';
import AlgemeneInfo from './algemeneInfo';
import './Account_Detail.css';


export const AccountDetailComponent = () => {
    const { user } = authUtils();
    const location = useLocation();

    const isCurrentPath = (path) => location.pathname === path;

    return (
        <Container className="mb-5">
                <Row>
                    <Col>
                            {!isCurrentPath('/account/accountOverzicht') && (
                                <div className="centered-button-container">
                                <Link to={'/account/accountOverzicht'}>
                                    <Button>Account overzicht</Button>
                                </Link>
                                </div>
                            )}
                        {!isCurrentPath('/account/beheren') && (
                            <Row className="align-items-center profile-header mb-5">
                                <Col md={4}>
                                    <img
                                        src={user?.picture}
                                        alt="Profile"
                                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                                    />
                                </Col>
                                <Col md={8}>
                                    <h2>{user?.name}</h2>
                                    <p className="lead text-muted">{user?.email}</p>
                                </Col>
                                <Link className="beheerAccountButton" to={'/account/beheren'}>
                                    <Button>Beheer accountgegevens</Button>
                                </Link>
                            </Row>
                        )}

                    </Col>
                </Row>
            <Routes>
                <Route path="accountOverzicht" element={<AccountOverzicht/>}/>
                <Route path="beheren" element={<AlgemeneInfo/>}/>
            </Routes>
        </Container>
    );
};

export default AccountDetailComponent;