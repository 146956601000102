import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Col, Container, Row, Table} from 'reactstrap';
import {serverIP} from "../../../index";
import {useLocation} from 'react-router-dom';
import './Admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faFileExport} from '@fortawesome/free-solid-svg-icons';
import {DataFrame} from 'dataframe-js'
import AdminTableRow from "./AdminTableRow.js"

export const Algemeen = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [role, setRole] = useState();
    const [unacceptedEvents, setUnacceptedEvents] = useState([]);
    const location = useLocation();
    const [gebruikersText, setGebruikersText] = useState("Exporteren")
    const [donatiesText, setDonatiesText] = useState("Exporteren")
    const [mergeText, setMergeText] = useState("Exporteren")
    const [donationFileError, setDonationFileError] = useState(false)
    const [gebruikerFileError, setGebruikerFileError] = useState(false)

    const getUserRoles = async () => {
        setRole(user["http://localhost/roles"]?.includes("Admin"))
    }

    useEffect(() => {
        if(isAuthenticated){
            getUserRoles();
            if(role) {
                fetchUnacceptedEvents()
            }
        }
    }, [user, isAuthenticated, role]);

    const fetchUnacceptedEvents = async () => {
        try {
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/unaccepted`, { method: "GET" });
            const body = await response.json();
            setUnacceptedEvents(body); // Assuming you want to show all unaccepted events for admin
        } catch (e) {
            console.log(e);
        }
    };

    const exportUsers = async () => {
        setGebruikersText("Loading...")
        const token = await getAccessTokenSilently()
        const url = await fetch(`${window.location.protocol}//${serverIP}/users`, {headers: {authorization: `Bearer ${token}`}}).then(res => res.text()).catch(res => console.log(res))
        const a = document.createElement('a');
        a.style.display = 'none';
        // Create a blob URL for the text
        a.href = url;
        // Set the filename for the downloaded file
        a.download = 'filename.txt'; // You can set the filename to whatever you want
        // Append the anchor to the document body
        document.body.appendChild(a);
        // Programmatically click on the anchor to trigger the download
        a.click();
        // Cleanup: remove the anchor and revoke the URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setGebruikersText("Exporteren")
    }
    const exportDonations = async (e) => {
        setDonatiesText("Loading...")
        const token = await getAccessTokenSilently()
        const ans = await fetch(`${window.location.protocol}//${serverIP}/donation/export`, {headers:{authorization: `Bearer ${token}`}})

        if(ans.status!==200){
            setDonatiesText("Er liep iets mis, probeer later opnieuw")
        }
        else{
            const blob = await ans.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'donations.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
            setDonatiesText("Exporteren")
        }
    }

    const merge = async(e) => {
        setMergeText("Loading")
        const donatieFile = document.getElementById("donatie_csv").files[0]
        const gebruikersFile = document.getElementById("gebruiker_csv").files[0]
        const donationDF = await DataFrame.fromCSV(donatieFile).then(df => df)
        let gebruikersDF = await DataFrame.fromCSV(gebruikersFile).then(df => df)
        const donationCols = donationDF.listColumns()
        const gebruikersCols = gebruikersDF.listColumns()
        if(!donationCols.includes("user_id") || !donationCols.includes("bedrag")){
            // verkeerd donatie bestand opgegeven
            setDonationFileError(true)
        }
        else if(!gebruikersCols.includes("user_id") || !gebruikersCols.includes("email")){
            // verkeerd gebruikers bestand opgegeven
            setGebruikerFileError(true)
        }
        else{
            for (let el of gebruikersCols) {
                gebruikersDF = gebruikersDF.withColumn(el, (row) => row.get(el).slice(1))
            }
            const merged = donationDF.join(gebruikersDF, "user_id", "left")
            let ans = merged.toCSV()
            // download als csv
            const url = window.URL.createObjectURL(new Blob([ans]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'donations_met_gebruikers.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
            setMergeText("Exporteren")
            setGebruikerFileError(false)
            setDonationFileError(false)
        }
    }
    return (
        <Container className="mb-5">
            {role && (
                <div className="bodyAdmin">
                    <Row>
                        <Row>
                            <Row>
                                <h2 className="align-rightOngeaccepteerde-evenementen">Ongeaccepteerde evenementen</h2>
                                {unacceptedEvents.length > 0 && (
                                    <Table striped className="tableEvenementen">
                                        <thead>
                                        <tr>
                                            <th>Event Naam</th>
                                            <th>Organisator</th>
                                            <th>Datum</th>
                                            <th>Accepteren</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {unacceptedEvents.map((event, index) => (
                                            <AdminTableRow key={index} event={event} fetchUnacceptedEvents={fetchUnacceptedEvents} index={index} />
                                        ))}
                                        </tbody>
                                    </Table>
                                )}
                                {unacceptedEvents.length === 0 && (
                                    <div className="centered-message">
                                        <div className="melding"> Geen ongeaccepteerde evenementen gevonden <FontAwesomeIcon
                                            className="meldingCheck" icon={faCheck}/></div>
                                    </div>
                                )}
                            </Row>

                            <Row>
                                <h2 className="align-right">Gebruikers afhalen in csv</h2>
                                <Button className="exporteren" onClick={() => exportUsers()}>{gebruikersText} <FontAwesomeIcon icon={faFileExport} /></Button>
                            </Row>
                            <Row>
                                <h2 className="align-right">Donaties afhalen in csv</h2>
                                <Button className="exporteren" onClick={() => exportDonations()}>{donatiesText} <FontAwesomeIcon icon={faFileExport} /></Button>
                            </Row>
                            <Row>
                                <h2 className="align-right">Samenvoegen donaties en gebruikers</h2>
                            </Row>
                            <Row>
                                <Col>
                                    <h4>Donaties.csv</h4>
                                    <input type="file" accept=".csv" id="donatie_csv" />
                                    {donationFileError && <div style={{ color: 'red' }}>Gegeven bestand is onjuist</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4 className="gebruikersCSVtitle">Gebruikers.csv</h4>
                                    <input type="file" accept=".csv" id="gebruiker_csv" />
                                    {gebruikerFileError && <div style={{ color: 'red' }}>Gegeven bestand is onjuist</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Button className="exporterenLastButton" onClick={merge}>
                                    {mergeText} <FontAwesomeIcon icon={faFileExport} />
                                </Button>
                            </Row>
                        </Row>
                    </Row>
                </div>
            )}
        </Container>
    );
};

export default Algemeen