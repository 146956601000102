// Import React and other necessary elements
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Add_event from './add_event'; // Assume Add_event is in the same directory

const AddEventWithAuth = () => {
    const { isAuthenticated, loginWithRedirect, user } = useAuth0();

    // Here, you could decide to redirect or conditionally render Add_event
    if (!isAuthenticated) {
        loginWithRedirect();
        return null; // or a loading indicator
    }

    return <Add_event isAuthenticated={isAuthenticated} user={user} />;
};

export default AddEventWithAuth;
