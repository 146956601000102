import React from 'react';
import {Link} from 'react-router-dom';
import joining_hands from '../img/afbeelding_home.jpg';
import '../pages/Home.css';


function Home() {
    return (
        <div className="home-component">
            <div className="home">
                <div className="overlay"></div>
                <img src={joining_hands} alt="Marathon for Charity" className="background-image"/>
                <div className="home-content">
                    <div className="text-box">
                        <h1 className="title">Een kleine gift een groot verschil!</h1>
                        <p className="subtitle">De Hersenletsel Liga vzw put al haar inkomsten uit giften en
                            lidmaatschapsgelden.</p>
                    </div>
                    <div className="buttons">
                        <Link to="/doneren" className="button donate">Doneer Nu - Elke Euro Telt!</Link>
                        <Link to="/add_event" className="button event">Start Je Eigen Fondsenwervingsactie!</Link>
                    </div>
                </div>
            </div>
            <div className='donation-info'>
                <div className='donation-info-text'>
                    <p>Voor onze werking zijn we zeer afhankelijk van giften. Een klein gebaar kan grote gevolgen
                        hebben. Alle beetjes helpen om de levenskwaliteit van personen met een niet-aangeboren
                        hersenletsel, hun familie en mantelzorgers te verbeteren.</p>
                    <p>Alle giften van minstens 40 € aan de Hersenletsel Liga vzw geven je recht op een fiscaal attest.
                        Daarvoor hebben we ook jouw rijksregisternummer nodig.</p>
                </div>
                <div class="donation-impact-list">
                    <h3><strong>Wat we doen met jouw gift:</strong></h3>
                    <div class="impact-items">
                        <div class="impact-item">
                            <p>Eén van onze professionele medewerkers kan <strong>thuis langsgaan</strong> bij een
                                persoon met een hersenletsel, of diens mantelzorger, om hun behoefte in kaart te brengen
                                en de persoon verder te helpen met dagdagelijkse dingen.</p>
                        </div>
                        <div class="impact-item">
                            <p>5 mensen telefonisch via de <strong>Hersenletsel Lijn</strong> een antwoord geven op hun
                                informatievraag.</p>
                        </div>
                        <div class="impact-item">
                            <p> Onze <strong>Jobcoach</strong> kan samen met de persoon met NAH bekijken of terug aan
                                het werk gaan mogelijk is, en welk werk de persoon nog wil of kan doen en onder welke
                                vorm.</p>
                        </div>
                        <div class="impact-item">
                            <p>De liga helpt mensen mee op weg in hun zoektocht naar <strong>zinvolle
                                dagbesteding</strong>. Dat kan gaan van vrijwilligerswerk, aangepaste hobbies, sporten
                                op maat, enz.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Home;