import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import brain from "../img/brain.svg";
import facebook from '../img/facebooklogo.png';
import link from '../img/link.png';
import whatsapp from '../img/whatsapp.png';
import { serverIP } from "../index";
import DonationForm from "./Donation/donationForm";
import './detail.css';

const Detail = () => {
    const { user, isAuthenticated } = useAuth0();
    const [role, setRole] = useState();
    const eventid = window.location.pathname.split("/")[2];
    const [event, setEvent] = useState(null);
    const [userid, setUserid] = useState();
    const [url, setUrl] = useState();
    const [showShareOptions, setShowShareOptions] = useState(false);
    const [displayText, setDisplayText] = useState('omschrijving');
    const [showDonations, setShowDonations] = useState(true);
    const [showTopDonations, setShowTopDonations] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showDoneer, setShowDoneer] = useState(false);
    const [isAcceptedEvent, setAcceptEvent] = useState(false);
    const [isExpired, setExpired] = useState(false);
    const getUserRoles = async () => {
        setRole(user["http://localhost/roles"]?.includes("Admin"))
    }

    const fetchEvent = async () => {
        try {
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/` + eventid);
            const body = await response.json();
            setEvent(body);
            if(body.accepted){
                setAcceptEvent(true)
            }
            const eventDate = new Date(body.startDatum);
            const today = new Date();
            const isExpired = eventDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
            if(isExpired){
                setExpired(true);
            }

        }
        catch (e) {
            console.log(e);
        }
    }

    const handleShare = () => {
        setShowShareOptions(!showShareOptions);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(document.location.href).then(() => {
            alert('Link copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const toggleText = (textType) => {
        setDisplayText(textType);
    };

    // Format the date to DD/MM/YYYY
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const ImageModal = ({ isOpen, image, onClose }) => {
        if (!isOpen) return null;

        return (
            <div className="modal-backdrop">
                <div className="modal-content">
                    <img src={image} alt="Expanded view" />
                    <button className="close-button" onClick={onClose}>X</button>
                </div>
            </div>
        );
    };


    useEffect(() => {
        if(isAuthenticated){
            getUserRoles();
        }
        fetchEvent();
        //fetchUserid();
        if (url) {
            window.location.href = url;
        }
    }, [url, user, role,isAuthenticated]);

    const firstPhotoURL = event ? `${window.location.protocol}//${serverIP}/${event.id}/files/${event.fotoPath[0]}` : "";

    return (
        <div className="detail-container">
            {event ? (
                isAcceptedEvent || role ? (
                    <>
                    {
                    isExpired && (
                        <div>
                        <Alert severity="info"> Dit event is verlopen</Alert>
                        </div>
                        
                    )
                }
                    <div className="detail-image-container">
                        <img src={firstPhotoURL} alt="event-image" />
                    </div>
                    <div className="content">
                        <div className="left-content">
                            <h1 className="event-title">{event.eventNaam}</h1>
                            <p className="organizer">Georganiseerd door <strong>{event.organisator}</strong></p>
                            <p className="event-date">
                                Datum: {event.startDatum === event.eindDatum ? formatDate(event.startDatum) : `${formatDate(event.startDatum)} - ${formatDate(event.eindDatum)}`}
                            </p>

                            <div className="button-container">
                                <button className={`toggle-button ${displayText === 'omschrijving' ? 'active' : ''}`}
                                    onClick={() => toggleText('omschrijving')}
                                >
                                    Info
                                </button>
                                <button className={`toggle-button ${displayText === 'persoonlijkeReden' ? 'active' : ''}`}
                                    onClick={() => toggleText('persoonlijkeReden')}
                                >
                                    Persoonlijke reden
                                </button>
                            </div>
                            <div className="description">
                                {event && (
                                    <div>
                                        <p>{event[displayText] || 'Geen informatie beschikbaar.'}</p>
                                    </div>
                                )}
                            </div>
                            <div className="image-gallery">
                                <p>Afbeeldingen:</p>
                                {event.fotoPath.map((path, index) => (
                                    <img
                                        key={index}
                                        src={`${window.location.protocol}//${serverIP}/${event.id}/files/${path}`}
                                        alt={`Event ${index + 1}`}
                                        onClick={() => {
                                            setSelectedImage(`${window.location.protocol}//${serverIP}/${event.id}/files/${path}`);
                                            setIsModalOpen(true);
                                        }}
                                        style={{ width: '100px', height: '100px', margin: '5px' }}
                                    />
                                ))}
                            </div>

                        </div>
                        <div className="right-content">
                            {showDoneer ?(
                                <div>
                                <h3>Doneer</h3>
                                <DonationForm eventid={eventid} maandelijks={false} page={"events"}/>
                                </div>):<button className='donate-button' onClick={() => setShowDoneer(true)}>Doneer</button>
                            }
                            <button className="share-button" onClick={handleShare}>Deel</button>
                            {showShareOptions && (
                                <div className="share-options-container">
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.location.href)}`} target="_blank" rel="noopener noreferrer">
                                        <img src={facebook} alt="Facebook" />
                                        <span>Facebook</span>
                                    </a>
                                    <a href={`whatsapp://send?text=${encodeURIComponent(document.location.href)}`} target="_blank" rel="noopener noreferrer">
                                        <img src={whatsapp} alt="WhatsApp" />
                                        <span>WhatsApp</span>
                                    </a>
                                    <button onClick={copyToClipboard}>
                                        <img src={link} alt="Copy link" />
                                        <span>Kopieer link</span>
                                    </button>
                                </div>
                            )}

                            <div className="amount-raised">
                                <div className="event-image-small-container">
                                    <ProgressBar goal={event.doel} current={event.totaalBedrag} />
                                </div>
                                <div className="raised-container">
                                    <p className="raised">{`€${event.totaalBedrag}`} / €{event.doel}</p>
                                </div>
                            </div>

                            <div className="donations-list">
                                <h3>
                                    Donaties
                                    <button onClick={() => setShowDonations(!showDonations)}>
                                        {showDonations ? "▼" : "►"}
                                    </button>
                                </h3>
                                {showDonations && (
                                    <div className="donations-list-container">
                                        {event.donations && event.donations.length > 0 ? (
                                            event.donations.slice().reverse().map((donation, index) => (
                                                <div key={index} className="donation-item">
                                                    <span>Gedoneerd door: {donation.showName ? donation.showName : 'Anoniem'}</span>
                                                    <span> €{donation.amount}</span>
                                                </div>
                                            ))
                                        ) : (
                                            <p>Voor dit event zijn er nog geen donaties.</p>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="top-donations-list">
                                <h3>
                                    Top donaties
                                    <button onClick={() => setShowTopDonations(!showTopDonations)}>
                                        {showTopDonations ? "▼" : "►"}
                                    </button>
                                </h3>
                                {showTopDonations && (
                                    <div className="donations-list-container">
                                        {event.donations && event.donations.length > 0 ? (
                                            event.donations
                                                .slice() // Create a copy of the array to avoid mutating the original
                                                .sort((a, b) => b.amount - a.amount) // Sort donations in descending order by amount
                                                .map((donation, index) => (
                                                    <div key={index} className="donation-item">
                                                        <span>Gedoneerd door: {donation.showName ? donation.showName : 'Anoniem'}</span>
                                                        <span>{formatDate(donation.donationDate)}</span>
                                                        <span> €{donation.amount}</span>
                                                    </div>
                                                ))
                                        ) : (
                                            <p>Voor dit event zijn er nog geen donaties.</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <ImageModal
                        isOpen={isModalOpen}
                        image={selectedImage}
                        onClose={() => setIsModalOpen(false)}
                    />
                </>
                ) : (
                    <div className='rejectedEvent'> Dit event is nog niet geaccepteerd</div>
                )
            ) : (
                <div>Loading event details...</div>
            )}
        </div>
    );
}

const ProgressBar = ({ goal, current }) => {
    let completion = (current / goal) * 100;
    let x = 0;
    if (completion > 100) {
        let n = current;
        n = n - goal;
        while (n > 0) {
            n = n - goal;
            x++;
        }
        completion = 100;
    }
    return (
        <svg width="100%" height="100%" viewBox="0 0 400 200" preserveAspectRatio="xMidYMid meet">
            <defs>
                {completion > 0 && (
                    <filter id="orange-fill">
                        <feFlood floodColor="orange" />
                        <feOffset dy={200 - (completion / 100) * 200}>
                            <animate attributeName="dy" from={200} to={200 - (completion / 100) * 200} dur="2s" />
                        </feOffset>
                        <feComposite operator="in" in2="SourceGraphic" />
                        <feComposite operator="over" in2="SourceGraphic" />
                    </filter>
                )}
            </defs>

            <image filter={completion > 0 ? "url(#orange-fill)" : ""} xlinkHref={brain} height={200} width={400} />

        </svg>
    );
};

export default Detail;
