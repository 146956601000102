import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {config, serverIP} from "../../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import emailjs from "@emailjs/browser";

function AdminTableRow({ event, fetchUnacceptedEvents}) {
    const {getAccessTokenSilently} = useAuth0()
    const [showDeleteReason, setShowDeleteReason] = useState(false)
    const [sendMail, setSendMail] = useState(false)
    const navigate = useNavigate()
    const [comment, setComment] = useState('');


    const handleRowClick = (eventId) => {
        // Navigate to another page when row is clicked
        navigate(`/detail/${eventId}`);
    };

    const acceptEvent = async (event) => {
        try {
            const token = await getAccessTokenSilently()
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/${event.id}/accept`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ accepted: true }),
            });

            if (response.status === 202) {
                fetchUnacceptedEvents();
            } else {
                // Handle response errors
                console.error("Failed to accept event.");
            }
        } catch (e) {
            console.error("Error accepting event:", e);
        }
    };

    const rejectEvent = async (event) => {
        try {
            const token = await getAccessTokenSilently()
            setShowDeleteReason(false)
            const response = await fetch(`${window.location.protocol}//${serverIP}/events/${event.id}`, {
                method: 'DELETE',
                headers: {
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ accepted: false }), // Depending on how your backend is set up, this may not be necessary
            });
            if (response.status === 204) {
                // Update the events list to reflect the change
                fetchUnacceptedEvents()
                if(sendMail) {
                    setSendMail(false)
                    sendRejectMail(event.organisator, event.id)
                }
            } else {
                // Handle response errors
                console.error("Failed to delete event. Status:", response.status);
            }
        } catch (e) {
            console.error("Error deleting event:", e);
        }
    };


    const sendRejectMail = async (organisator, eventid) => {
        const redenen = document.getElementById("redenen").value.trim()
        if(redenen.length>0) {
            const token = await getAccessTokenSilently();
            const mail = await fetch(`${window.location.protocol}//${serverIP}/user/${event.userSub.replace("|","%7C")}/mail`, {headers:{authorization: `Bearer ${token}`}}).then(res => res.text())
            const parameters = {
                "ontvanger": organisator,
                "email": mail,
                "redenen": redenen
            }
            emailjs.send(config.emailjs_serviceID, config.emailjs_temlapteID_delete_event, parameters, {
                publicKey: config.emailjs_publicKey,
            }).then(
                (response) => {
                    console.log('succes', response.status, response.text)
                },
                (error) => {
                    console.log("failed", error)
                }
            )
        }
        else {
            console.log("niet verzonden")
        }
    }

    return (
        <>
            <tr className="rowStyle" key={event.id}>
                <td className="textTd"  onClick={() => handleRowClick(event.id)}>{event.eventNaam}</td>
                <td className="textTd"  onClick={() => handleRowClick(event.id)}>{event.organisator}</td>
                <td className="textTd"  onClick={() => handleRowClick(event.id)}>{`${event.startDatum} - ${event.eindDatum}`}</ td>
                <td className="icon">
                    <FontAwesomeIcon icon={faCheckCircle} className="accept-icon" onClick={() => acceptEvent(event)} />
                    <FontAwesomeIcon icon={faTimesCircle} className="cancel-icon" onClick={() => setShowDeleteReason(true)} />
                </td>
            </tr>
            {showDeleteReason && (
                <>
                    <input type="checkbox" onChange={()=>setSendMail(!sendMail)} checked={sendMail}/>
                    <label className="LabelVerstuurMail">Verstuur mail</label>
                </>
            )}
            {sendMail && (
                <textarea
                    className="fullwidth"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Redenen voor verwijderen"
                    id="redenen"
                />
            )}
            {showDeleteReason && (
                <div className="buttonContainer">
                    <Button className="doubleCheck" onClick={() =>setShowDeleteReason(false) }>Niet verwijderen</Button>
                    <Button className="doubleCheckVerwijderen" onClick={() => rejectEvent(event)}>Verwijderen</Button>
                </div>
            )
            }
        </>
    );

}

export default AdminTableRow;