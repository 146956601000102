import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavbarBrand } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import {config} from "../index";

const Account = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout
  } = useAuth0();
  const [role, setRole] = useState(false);

  const getUserRoles = async () => {
    setRole(user["http://localhost/roles"]?.includes("Admin"))
  }

  useEffect(() => {
    if (isAuthenticated)
      getUserRoles(user?.sub)
  }, [user?.sub, isAuthenticated]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const logoutWithRedirect = () =>
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
      });

  const login = () => {
    loginWithRedirect({
      audience: `${config.audience}`,
      scope: 'openid profile email read:current_user',
      redirect_uri: 'http://localhost:3000'
    });
  };

  return (
      <Container>


        <NavbarBrand className="logo" />
        {!isAuthenticated ? (
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" onClick={() => login()}>Log in</NavLink>
            </li>
        ) : (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
              >
                <FontAwesomeIcon icon={faUserCircle} size="2x" style={{ cursor: 'pointer' }} />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={RouterNavLink} to="/account/accountOverzicht">
                  Account
                </DropdownItem>
                <DropdownItem divider />
                {role && (
                    <>
                      <DropdownItem tag={RouterNavLink} to="/admin/algemeen">
                        Admin
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                )}
                <DropdownItem onClick={() => logoutWithRedirect()}>
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
        )}
      </Container>
  );
};

export default Account;
