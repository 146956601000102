import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import joining_hands from '../../img/afbeelding_home.jpg';
import './Donation.css';
import DonationForm from "./donationForm";

const Donation = () => {
    const { isLoading } = useAuth0();

    return (
        <div className="donation-container">
            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-message">Loading...</div>
                </div>
            ) : (
                <>
                        <div className="unauthenticated-container">
                            <div className="donation-banner">
                                <div className="donation-overlay"></div>
                                <img src={joining_hands} alt="Marathon for Charity" className="donation-background-image" />
                                <div className="donation-content">
                                    <h1 className="donation-title">Een kleine gift een groot verschil!</h1>
                                    <p className="donation-subtitle">De Hersenletsel Liga vzw put al haar inkomsten uit giften en lidmaatschapsgelden.</p>
                                </div>
                            </div>
                                <DonationForm maandelijks={true} page={"doneren"}/>

                            <div className="Donationimpact-info">
                                <div className="donation-Donationimpact-list">
                                    <div className="Donationimpact-items">
                                        <div className="Donationimpact-item">
                                            <p>Door anoniem te doneren beschermt u uw privacy. Stripe bewaart alleen de noodzakelijke informatie zoals uw e-mailadres en bankgegevens.</p>
                                        </div>
                                        <div className="Donationimpact-item">
                                            <p>Elke donatie, hoe klein ook, maakt een verschil. Door een gift te doneren, helpt u ons bij het ondersteunen van personen met een niet-aangeboren hersenletsel en hun naasten.</p>
                                        </div>
                                        <div className="Donationimpact-item">
                                            <p> Steun ons op regelmatige basis en maak het verschil elke maand. Dit kunt u doen door u te abonneren. Elke maandelijkse bijdrage, hoe klein ook, telt voor ons!</p>
                                        </div>
                                    </div>
                                    <div className="donation-info-text">
                                        <p>Voor onze werking zijn we zeer afhankelijk van giften. Een klein gebaar kan grote gevolgen hebben. Alle beetjes helpen om de levenskwaliteit van personen met een niet-aangeboren hersenletsel, hun familie en mantelzorgers te verbeteren.</p>
                                        <p>Alle giften van minstens 40 € aan de Hersenletsel Liga vzw geven je recht op een fiscaal attest. Daarvoor hebben we ook jouw rijksregisternummer nodig.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </>
            )}
        </div>
    );
};

export default Donation;
